import { escapeRegExp, isEmptyObject } from './util';
import { DBEngine } from './db-engine';

const buildFilterQuery = (obj: any, condition: string) :any => {
	let fieldsQuery = [];
	for (const objKey in obj) {
		if (obj.hasOwnProperty(objKey)) {
			if (obj[objKey].type === 'logic') {
				const { condition, subFilters } = obj[objKey];
				if (Object.keys(subFilters).length > 0) {
					fieldsQuery.push(buildFilterQuery(subFilters, `$${condition}`));
				}
			} else {
				const { fieldName, condition, value, trueBackendType, filterType } =
					obj[objKey];

				let parsedValue =
					filterType === 'number' ? Number(value) : value.trim();

				if (fieldName !== 'none') {
					switch (condition) {
						case 'eq':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$eq: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$eq: parsedValue,
									},
								});
							break;
						case 'ne':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$ne: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$ne: parsedValue,
									},
								});
							break;
						case 'lt':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$lt: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$lt: parsedValue,
									},
								});
							break;
						case 'lte':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$lte: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$lte: parsedValue,
									},
								});
							break;
						case 'gt':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$gt: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$gt: parsedValue,
									},
								});
							break;
						case 'gte':
							if (trueBackendType === 'date' && filterType === 'date') {
								fieldsQuery.push({
									$expr: {
										$gte: [
											`$${fieldName}`,
											{
												$dateFromString: {
													dateString: value,
												},
											},
										],
									},
								});
							} else
								fieldsQuery.push({
									[fieldName]: {
										$gte: parsedValue,
									},
								});
							break;
						case 'contains':
							fieldsQuery.push({
								[fieldName]: {
									$regex: `.*${escapeRegExp(parsedValue)}.*`,
									$options: 'i',
								},
							});
							break;
						case 'doesnotcontains':
							fieldsQuery.push({
								[fieldName]: {
									$ne: {
										eq: {
											$regex: `.*${escapeRegExp(parsedValue)}.*`,
											$options: 'i',
										},
									},
								},
							});
							break;
						case 'startswith':
							fieldsQuery.push({
								[fieldName]: {
									$regex: `^${escapeRegExp(parsedValue)}`,
									$options: 'i',
								},
							});
							break;
						case 'endswith':
							fieldsQuery.push({
								[fieldName]: {
									$regex: `${escapeRegExp(parsedValue)}$`,
									$options: 'i',
								},
							});
							break;
						case 'daterange':
							const date = parsedValue.split('/');
							const fromDate = new Date(date[0]);
							const toDate = new Date(date[1]);

							fieldsQuery.push({
								[fieldName]: {
									$gte: new Date(fromDate.toISOString()),
									$lte: new Date(toDate.toISOString()),
								},
							});
							break;
						case 'exist':
							fieldsQuery.push({
								[fieldName]: {
									$exists: true,
								},
							});
							break;
						case 'nil':
							fieldsQuery.push({
								[fieldName]: {
									$eq: null,
								},
							});
							break;
						case 'blank':
							fieldsQuery.push({
								[fieldName]: {
									$eq: '',
								},
							});
							break;
						default:
							break;
					}
				}
			}
		}
	}

	if (condition === '') {
		return fieldsQuery;
	} else
		return {
			[condition]: fieldsQuery,
		};
};

const MongoDBEngine: DBEngine = {
	getConfigData: ():object => {
		return {
			editorType: 'json',
			defaultQueryString: '[]',
		};
	},
	getQueryPrefix: (collectionName: string):string => {
		return `db.getCollection("${collectionName}").aggregate();`;
	},
	getFullDbQuery: (collectionName: string, dbQuery: string):string => {
		return `db.getCollection("${collectionName}").aggregate(${dbQuery});`;
	},
	getQuery: (filterObj: object, sortObj: object, paginationObj: any): any => {
		let query = [];

		if (!isEmptyObject(sortObj)) {
			query.push({
				$sort: sortObj,
			});
		}

		const filterQuery = buildFilterQuery(filterObj, '');
		let matchQuery = {};
		if (filterQuery && filterQuery.length > 0) {
			matchQuery = {
				$and: filterQuery,
			};
			query.push({
				$match: {
					$and: filterQuery,
				},
			});
		}

		if (paginationObj) {
			const { pageNumber, count } = paginationObj;
			query.push({ $skip: pageNumber * count }, { $limit: count });
		}

		return {
			dbStringifyQuery: JSON.stringify(query),
			dbMatchStringifyQuery: JSON.stringify(matchQuery),
		};
	}
}

export default MongoDBEngine;
